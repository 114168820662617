.saturate { filter: saturate(3); }
.grayscale { filter: grayscale(100%); }
.contrast { filter: contrast(160%); }
.brightness { filter: brightness(0.25); }
.blur { filter: blur(3px); }
.invert { filter: invert(100%); }
.sepia { filter: sepia(100%); }
.huerotate { filter: hue-rotate(180deg); }
.rss.opacity { filter: opacity(50%); }


* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

body {
   background: #1E1A19;
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.fondo{
   background-image: url(../../public/fondo.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;

   @media (max-width: 480px) {
      background-image: url(../../public/fondoneg2_mobil.png);
      background-size: cover;
   }
}

.fondo_result {
   background-image: url(../../public/fondoblanco.png);
   background-position: right;
   background-repeat: no-repeat;
   background-size: cover;
   overflow: hidden;

   @media (max-width: 480px){
  
           background-image: url(../../public/fondoblanc_mobil.png);
   }
}

.container {
   display: grid;
   place-content: center;
   justify-items: center;
   max-width: 600px;
   width: 100%;
   height: 100vh;

   padding: 1rem;
   margin: auto;
   gap: 1rem;
   color: white;
   text-align: center;
   
}
.container_result {
   position: relative;
   display: grid;
   place-content: center;
   justify-items: center;
   max-width: 600px;
   width: 100%;
   height: 100vh;

   padding: 1rem;
   margin: auto;
   gap: 1rem;
   color: white;
   text-align: center;


   .inner_content {
         display: flex;
         gap: 1rem;
         align-items: center;
         border-top: 1px solid #000;
         border-bottom: 1px solid #000;
         padding: 1rem 0;
         z-index: 1;
   
         p {
            color: black;
         }
      }

}



@media (max-width: 480px){
   .container_result{
      .inner_content {
            flex-direction: column;
            border-top: none;

            p {
               border-top: 1px solid #000;
               padding-top: 1rem;
               text-align: center;
            }
      }
   }
 
}

.logo {
   max-width: 300px;
   width: 100%;
}

form {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1rem;
   max-width: 500px;
   width: 100%;
}

#error {
   color: red;
}

input {
   padding: 1rem;
   font-size: 1rem;
   width: 100%;
   outline: none;
}

button {
   padding: .5rem;
   font-size: 1.5rem;
   width: 100%;
   background: #BF1E2E;
   color: white;
   border: none;
   cursor: pointer;
   opacity: .8;
}

button:hover {
   opacity: 1;
}

// @media (max-width: 480px){
//    .container {
   
//          &::after {
//             content: '';
//             height: 250px;
//             width: 250px;
//             position: absolute;
//             bottom: 0;
//             left: -50px;
//             background-image: url(../../public/magiequipos.png);
//             background-position: bottom;
//             background-repeat: no-repeat;
//             background-size: contain;
//          }
   
//       }
// }


/*
result
*/

.result_img_success {
   height: 125px;
   max-width: 125px;
   border: 5px solid green;
   border-radius: 50%;
   padding: 1.5rem;
   margin-bottom: 1rem;
}

.result_img_danger {
   height: 125px;
   max-width: 125px;
   border: 5px solid red;
   border-radius: 50%;
   padding: 1.5rem;
   margin-bottom: 2rem;
}

.content_text {
   text-align: center;
   margin-bottom: 1rem;
}

.result_text {
   font-size: 1.3rem;
   padding-bottom: .5rem;
   color: white;
   text-transform: uppercase;
}

.link{
   color: #9e1d29;
   text-decoration: none;
   display: block;
   font-size: 1.1rem;
   margin-top: .5rem;
}

.link:hover{
  color: #2f80e0;
  text-decoration: underline;
}

.result_message {
   font-size: 1.2rem;
   color: white;
   text-align: left;
}

/*
spinner
*/

.lds-roller {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}

.lds-roller div {
   animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   transform-origin: 40px 40px;
}

.lds-roller div:after {
   content: " ";
   display: block;
   position: absolute;
   width: 7px;
   height: 7px;
   border-radius: 50%;
   background: #fff;
   margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
   animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
   top: 63px;
   left: 63px;
}

.lds-roller div:nth-child(2) {
   animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
   top: 68px;
   left: 56px;
}

.lds-roller div:nth-child(3) {
   animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
   top: 71px;
   left: 48px;
}

.lds-roller div:nth-child(4) {
   animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
   top: 72px;
   left: 40px;
}

.lds-roller div:nth-child(5) {
   animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
   top: 71px;
   left: 32px;
}

.lds-roller div:nth-child(6) {
   animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
   top: 68px;
   left: 24px;
}

.lds-roller div:nth-child(7) {
   animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
   top: 63px;
   left: 17px;
}

.lds-roller div:nth-child(8) {
   animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
   top: 56px;
   left: 12px;
}

@keyframes lds-roller {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}


/*
animated result
*/
.f-modal-alert .f-modal-icon {
   border-radius: 50%;
   border: 4px solid gray;
   box-sizing: content-box;
   height: 80px;
   margin: 20px auto;
   padding: 0;
   position: relative;
   width: 80px;


   // Success icon
   &.f-modal-success,
   &.f-modal-error {
      border-color: #A5DC86;

      &:after,
      &:before {
         background: #fff;
         content: '';
         height: 120px;
         position: absolute;
         transform: rotate(45deg);
         width: 60px;
         background: #1e1a19;
      }

      &:before {
         border-radius: 120px 0 0 120px;
         left: -33px;
         top: -7px;
         transform-origin: 60px 60px;
         transform: rotate(-45deg);
      }

      &:after {
         border-radius: 0 120px 120px 0;
         left: 30px;
         top: -11px;
         transform-origin: 0 60px;
         transform: rotate(-45deg);
      }

      .f-modal-placeholder {
         border-radius: 50%;
         border: 4px solid rgba(165, 220, 134, .2);
         box-sizing: content-box;
         height: 80px;
         left: -4px;
         position: absolute;
         top: -4px;
         width: 80px;
         z-index: 2;
      }

      .f-modal-fix {
         background-color: #1e1a19;
         height: 90px;
         left: 28px;
         position: absolute;
         top: 8px;
         transform: rotate(-45deg);
         width: 5px;
         z-index: 1;
      }

      .f-modal-line {
         background-color: #A5DC86;
         border-radius: 2px;
         display: block;
         height: 5px;
         position: absolute;
         z-index: 2;

         &.f-modal-tip {
            left: 14px;
            top: 46px;
            transform: rotate(45deg);
            width: 25px;
         }

         &.f-modal-long {
            right: 8px;
            top: 38px;
            transform: rotate(-45deg);
            width: 47px;
         }
      }
   }

   // Error icon
   &.f-modal-error {
      border-color: #F27474;

      .f-modal-x-mark {
         display: block;
         position: relative;
         z-index: 2;
      }

      .f-modal-placeholder {
         border: 4px solid rgba(200, 0, 0, .2);
      }

      .f-modal-line {
         background-color: #F27474;
         top: 37px;
         width: 47px;

         &.f-modal-left {
            left: 17px;
            transform: rotate(45deg);
         }

         &.f-modal-right {
            right: 16px;
            transform: rotate(-45deg);
         }
      }
   }

   // Warning icon

   &.f-modal-warning {
      border-color: #F8BB86;
      display: flex;

      &:before {
         animation: pulseWarning 2s linear infinite;
         background-color: #fff;
         border-radius: 50%;
         content: "";
         display: inline-block;
         height: 100%;
         opacity: 0;
         position: absolute;
         width: 100%;
      }

      &:after {
         // background-color: #fff;
         border-radius: 50%;
         content: '';
         display: block;
         height: 100%;
         position: absolute;
         width: 100%;
         z-index: 1;
      }
   }

   &.f-modal-warning .f-modal-body {
      background-color: #F8BB86;
      border-radius: 2px;
      height: 47px;
      left: 50%;
      margin-left: -2px;
      position: absolute;
      top: 10px;
      width: 5px;
      z-index: 2;
   }

   &.f-modal-warning .f-modal-dot {
      background-color: #F8BB86;
      border-radius: 50%;
      bottom: 10px;
      height: 7px;
      left: 50%;
      margin-left: -3px;
      position: absolute;
      width: 7px;
      z-index: 2;
   }

   +.f-modal-icon {
      margin-top: 50px;
   }
}

.animateSuccessTip {
   animation: animateSuccessTip .75s;
}

.animateSuccessLong {
   animation: animateSuccessLong .75s;
}

.f-modal-icon.f-modal-success.animate:after {
   animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
   animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
   animation: animateErrorIcon .5s;
}

.animateXLeft {
   animation: animateXLeft .75s;
}

.animateXRight {
   animation: animateXRight .75s;
}

.scaleWarning {
   animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
   animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {

   0%,
   54% {
      width: 0;
      left: 1px;
      top: 19px;
   }

   70% {
      width: 50px;
      left: -8px;
      top: 37px;
   }

   84% {
      width: 17px;
      left: 21px;
      top: 48px;
   }

   100% {
      width: 25px;
      left: 14px;
      top: 45px;
   }
}

@keyframes animateSuccessLong {

   0%,
   65% {
      width: 0;
      right: 46px;
      top: 54px;
   }

   84% {
      width: 55px;
      right: 0;
      top: 35px;
   }

   100% {
      width: 47px;
      right: 8px;
      top: 38px;
   }
}

@keyframes rotatePlaceholder {

   0%,
   5% {
      transform: rotate(-45deg);
   }

   100%,
   12% {
      transform: rotate(-405deg);
   }
}

@keyframes animateErrorIcon {
   0% {
      transform: rotateX(100deg);
      opacity: 0;
   }

   100% {
      transform: rotateX(0deg);
      opacity: 1;
   }
}

@keyframes animateXLeft {

   0%,
   65% {
      left: 82px;
      top: 95px;
      width: 0;
   }

   84% {
      left: 14px;
      top: 33px;
      width: 47px;
   }

   100% {
      left: 17px;
      top: 37px;
      width: 47px;
   }
}

@keyframes animateXRight {

   0%,
   65% {
      right: 82px;
      top: 95px;
      width: 0;
   }

   84% {
      right: 14px;
      top: 33px;
      width: 47px;
   }

   100% {
      right: 16px;
      top: 37px;
      width: 47px;
   }
}

@keyframes scaleWarning {
   0% {
      transform: scale(1);
   }

   30% {
      transform: scale(1.02);
   }

   100% {
      transform: scale(1);
   }
}

@keyframes pulseWarning {
   0% {
      background-color: #fff;
      transform: scale(1);
      opacity: 0.5;
   }

   30% {
      background-color: #fff;
      transform: scale(1);
      opacity: 0.5;
   }

   100% {
      background-color: #F8BB86;
      transform: scale(2);
      opacity: 0;
   }
}

@keyframes pulseWarningIns {
   0% {
      background-color: #F8D486;
   }

   100% {
      background-color: #F8BB86;
   }
}